.card-container {
  margin: 10px;
  background-color: white;
  height: auto;
  border-radius: 14px;
  border: 1px solid rgb(119, 119, 119);
}

.header-carteirinha {
  background-color: #3d79b8;
  text-align: center;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.bottom-carteirinha {
  text-align: center;
}
hr {
  width: 70%;
  color: white;
}
.conteudo {
  background-color: #3d79b8;
  color: white;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
.div-vencimento {
  border: 2px solid white;
  border-radius: 10px;
  margin: auto;
  margin-left: 40px;
  margin-right: 40px;
}
img {
  margin: auto;
  width: 100px;
  max-height: 100px;
  border-radius: 50%;
  margin-top: 10px;
}
.paragrafo-federacao {
  font-size: 12px;
  text-align: center;
}
.rodape {
  background-color: #3d79b8;
  height: 20px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}
.nome {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.2rem;
}
.bold-text {
  font-weight: bold;
  font-size: 1.1rem;
  text-align: center;
}

.normal-text {
  font-weight: normal;
  font-size: 0.95rem;
  color: hsl(0, 0%, 50%);
  text-align: center;
  padding-bottom: 10px;
}

/* SOCIAL STATS */
.smaller-text {
  font-weight: normal;
  font-size: 0.7rem;
  color: hsl(0, 0%, 50%);
  text-align: center;
  letter-spacing: 1px;
  padding-bottom: 20px;
  line-height: 5px;
}

.social-container {
  display: flex;
  border-top: solid rgb(206, 206, 206) 1px;
  text-align: center;
}

.followers {
  flex: 1;
}

.likes {
  flex: 1;
}

.photos {
  flex: 1;
}
